import { type Price } from '@backmarket/http-api'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'

export function getHighestSubsidy(
  offers: GetProductResponse['includedServiceOffers']['mobilePlans'],
) {
  if (offers.length === 0) {
    return null
  }

  return offers.reduce<Price>((highestSubsidy, offer) => {
    const highestAmount = parseInt(highestSubsidy.amount, 10)
    const amount = parseInt(offer.subsidy.amount, 10)

    if (amount > highestAmount) return offer.subsidy

    return highestSubsidy
  }, offers[0].subsidy)
}
